<template>
    <div>
        <transition :name="transitionName" mode="out-in"><router-view class="child-view"></router-view> </transition>
    </div>
</template>

<script>
export default {
    name: 'IndexLayout',
	data() {
		return {
			transitionName: 'slide-right',
			transitionPage: 1,
		};
    },
	beforeRouteUpdate(to, from, next) {
		let dict = {
			'/links': 1,
			'/campaigns': 10,
			'/tags': 20,
		};
		let toWeight = typeof dict[to.path] != 'undefined' ? dict[to.path] : 0;
		let fromWeight = typeof dict[from.path] != 'undefined' ? dict[from.path] : 0;

		this.transitionName = toWeight < fromWeight ? 'slide-left' : 'slide-right';
		next();
    },
    methods: {
        is_active(route) {
            return this.$route.path.indexOf(route) === 0
        }
    }
}
</script>

<style>
.main-card {
    background-color: #FDFDFF;
}
</style>